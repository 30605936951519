<template>
  <b-card
    no-body
    class="mb-0 border-0"
  >
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div class="reward-section">
        <div class="total-rewards-savings-text">
          Rewards Balance: <span class="color-blue"> {{ balance }} credit points available </span>
        </div>
        <referrals-invite />
      </div>
      <b-table
        v-if="isLoaded"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="rewards"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No rewards found"
      >
        <template #cell(createdAt)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 text-info"
            />
            <span class="align-text-top">{{ getDateFromTimestamp(data.item.createdAt) }}</span>
          </div>
        </template>
        <template #cell(amount)="data">
          <span
            class="align-text-top"
            :class="data.item.transactionType === WALLET_TRANSACTION_TYPE.CREDIT && 'color-light-green'"
          >
            {{ data.item.transactionType === WALLET_TRANSACTION_TYPE.DEBIT ? -data.item.amount : data.item.amount }} credit points</span>
        </template>
        <template #cell(description)="data">
          <span>{{ data.item.description }}</span>
          <b-link
            v-if="data.item.orderId"
            class="color-blue d-inline-block order-id-link"
            :to="{ name: 'order/detail', params: { orderId: data.item.orderId } }"
          >{{ data.item.orderSeqId }}</b-link>
        </template>
      </b-table>
      <div
        v-if="totalRewards"
        class="mx-2 mb-2 mt-auto"
      >
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="elements-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRewards"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-card>
</template>

<script>
import {
 BTable, BRow, BCol, BPagination, BCard, BSpinner, BLink,
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { apiToastError } from '@/@core/utils/toast'
import { getDateFromTimestamp } from '@/common-utils'
import { constants as c } from '@kingpin-global/kingpin-utils-frontend'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { formatObject } from '@/@core/utils/utils'
import { GET_REWARDS } from '@/store/modules/wallet.module'
import { GET_REWARDS_TABLE_COLUMNS } from '@/table-columns-constants'
import ReferralsInvite from '../referral/ReferralsInvite.vue'

const { WALLET_TRANSACTION_TYPE } = c

export default {
    components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BCard,
    BLink,
    BSpinner,
    FeatherIcon,
    ReferralsInvite,
},
    data() {
    return {
      isLoaded: true,
      rewards: [],
      currentPage: 1,
      perPage: 10,
      totalRewards: 0,
      tableColumns: GET_REWARDS_TABLE_COLUMNS(),
      balance: 0,
      WALLET_TRANSACTION_TYPE,
      getDateFromTimestamp,
    }
    },
    created() {
      this.loadRewards()
    },
    methods: {
      loadRewards() {
      this.isLoaded = false
      this.$store.dispatch(GET_REWARDS).then(res => {
        const result = res.data.data
        this.rewards = result.rewards
        this.totalRewards = this.rewards.length
        this.balance = result.balance
        this.isLoaded = true
        analytics.track(constants.TRACKS.PAGES.RETAILER_OPENS_REWARDS, formatObject({ rewardsCount: this.totalRewards, totalCreditsBalance: this.balance, totalCreditsReceived: this.getTotalRewardsAmount(this.rewards) }))
      }).catch(err => {
        apiToastError(err)
        this.isLoaded = true
      })
      },
      getTotalRewardsAmount(rewards = []) {
        let totalRewards = 0
        rewards.forEach(reward => {
          if (reward.transactionType !== WALLET_TRANSACTION_TYPE.DEBIT) {
            totalRewards += reward.amount
          }
        })
        return totalRewards
      },
    },

}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce.scss';

.order-id-link {
  padding-left: 4px;
}
</style>
